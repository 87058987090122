<template>
  <div>
    <div class="main-content">
<!--      顶部商品详情信息内容区域-->
      <GoodSelect
        v-if="Object.keys(productObj).length > 0 && productSpec.length > 0"
        :product-obj="productObj"
        :product-spec="productSpec"
        :product-spec-sku="productSpecSku"
        :spinning="spinning"
        @ok="emitOk">
      </GoodSelect>
      <div v-else class="example">
        <a-spin size="large" />
      </div>
<!--      底部商品描述-->
      <div class="commdis">
        <div class="commdisleft">
          <div class="title">推荐商品</div>
          <div
            v-for="(item) in cateList"
            :key="item.id"
            class="list "
            @click="() => { $router.replace('/commodity/' + item.id); $router.go(0) }">
            <img :src="item.imgUrl" style="width: 100px;height: 100px;">
            <div class="flex-column flex-row-between p-5">
              <Ellipsis :length="25" style="font-weight: 700" tooltip>{{ item.name }}</Ellipsis>
              <strong v-if="Object.keys(userInfo).length > 0 || userInfo.infoProfect == 1">{{ '¥' + item.tradePrice }}</strong>
              <div>{{ Object.keys(userInfo).length == 0 ? '价格登录可见' : userInfo.infoProfect === 0 ? '账号信息未完善' : '' }}</div>
            </div>
          </div>
        </div>
        <div style="width:950px;">
          <el-tabs type="border-card">
            <el-tab-pane label="商品详情">
              <div class="scale">
                <p>品牌：{{ productObj.brandName }}</p>
                <p>原产地：{{ productObj.countryName }}</p>
              </div>
              <div id="richText" class="" v-html="productObj.detailHtml"></div>
            </el-tab-pane>
            <el-tab-pane label="产品参数">
              <a-descriptions bordered class="p-10" title="产品参数表">
                <a-descriptions-item :span="3" label="重量">
                  {{ productObj.weight === null ? '暂无数据' : productObj.weight }}
                </a-descriptions-item>
                <a-descriptions-item v-for="(item) in productSpec" :key="item.id" :label="item.name" :span="3">
                  {{ item.value.join(',') }}
                </a-descriptions-item>
              </a-descriptions>
            </el-tab-pane>
            <el-tab-pane label="素材下载">
              <div class="flex-row-center flex-col-center flex-column" style="position: relative;">
                <a-button v-if="infoProfect != '0' && userIsOpenDown == 1" :disabled="showLoad" style="margin-bottom: 15px;" type="primary" @click="deFiedls"> 一键下载资源包</a-button>
                <div v-show="showLoad" class="first-loading-wrp ">
                  <div style="display: flex; justify-content: center; z-index: 1000; align-items: center;">
                    <img src="/img/lo.gif">
                  </div>
                </div>
                <div v-show="!showLoad" id="richText" class="" v-html="productObj.detailHtml"></div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { getCateProducts, getProductObj, getProductSpec } from '@/api/Product'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

import Ellipsis from '@/components/Ellipsis/Ellipsis'
import { mapGetters } from 'vuex'
import { downloadZipFile } from '@/api/Address'
import GoodSelect from '@/views/GoodsDetail/GoodsSelect'
import {getMoreSkuData} from '@/api/SelectGoods'



export default {
  name: 'Commodity',
  components: { GoodSelect, Ellipsis },
  data() {
    return {
      spinning: true,
      userIsOpenDown: 0,  // 默认资源包下载的状态
      goodsId: '',  // 获取商品ID
      // 轮播图
      productObj: {},  // 商品详情基本数据
      productSpec: [], // 商品Sku规格数据
      productSpecSku: [], // 商品Sku数据
      cateList: [],  // 推荐商品数据
      // 商品选择
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      GuiSku: [],
      showLoad: false,  // 素材库下载的加载控制
    }
  },
  watch: {
    '$route'(to, from) {
      this.$router.go(0)
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect', 'webKeyword']),
  },
  created() {
    this.userIsOpenDown = this.$store.state.user.isOpenDown  // 获取资源包下载的状态
    this.goodsId = this.$route.params.id  // 获取商品ID
    this.initGoodsFn()
  },
  mounted() {
    let PAGE_URL = ''
    if (process.env.NODE_ENV === 'pre') {
      PAGE_URL = `http://cg.${this.webKeyword.OPEN_DOMAIN}/`
    } else if (process.env.NODE_ENV === 'production') {
      PAGE_URL = `http://${this.webKeyword.OPEN_DOMAIN}/`
    } else {
      PAGE_URL = 'http://localhost:8080/api/'
    }
  },
  methods: {
    initGoodsFn () {
      // 商品基本信息数据
      getProductObj(this.goodsId).then((res) => {
        this.productObj = res.data
        this.moreSkuData()
      })
      // 规格表数据
      getProductSpec(this.goodsId).then((res) => {
        this.productSpec = res.data
      })
      // 推荐商品
      getCateProducts(this.goodsId).then((res) => {
        this.cateList = res.data || []
      })
    },
    moreSkuData() {
      this.spinning = true
      if (Object.keys(this.userInfo).length && 1 == this.infoProfect) {
        getMoreSkuData(this.goodsId).then((res) => {
          this.spinning = false
          let { data } = res
          this.productSpecSku = data
          this.productObj.skus = data
          // 重新给规格sku赋值
          this.getSkuDate(this.productObj)
        }).catch(err => {
          this.spinning = false
        })
      } else {
        this.spinning = false
      }
    },
    deFiedls() {
      this.showLoad = true
      // this.$message.info('资源下载中，请稍后！')
      downloadZipFile(this.$route.path.split('/')[2]).then(res => {
        let blob = new Blob([res], { type: 'application/zip' })
        this.showLoad = false
        this.$message.success('资源下载成功')
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, '导出数据源.zip')
          return
        }
        let url = window.URL.createObjectURL(blob)
        const link = document.createElement('a') // 创建a标签
        link.href = url
        link.download = `导出数据源` // 重命名文件
        link.click()
        URL.revokeObjectURL(url) // 释放内存
      }).catch(err => {
        this.showLoad = false
      })
    },
    getSkuDate(val) {
      // 处理sku数据内部的是否开启状态，
      // 将是否开启状态加入规格sku中
      // 1.定义一个新的sku数组
      const sku = val.skus
      // 2.将sku数组中的enable的状态值加入规格表数组中
      console.log('拿到sku数据', val)
      // 拿到禁用状态的sku数据后，重新赋值定义一个新数组
      let NewSku = []
      sku.forEach(element => {
        // 拿到为0 的数据
        if (element.enable == 0) {
          NewSku.push({
            enable: element.enable,
            specNames: element.specNames
          })
        }
      })
      this.GuiSku = NewSku
    },
    emitOk() {
      console.log('到这')
      getProductObj(this.goodsId).then((res) => {
        this.productObj = res.data
      })
    },
    /**  导出图片 */
    async exportsImageZip() {
      console.log('sss', this.productObj.id)
      downloadZipFile(this.productObj.id).then(res => {
        console.log('点击下载', res)
      })
      // const promiseList = []
      const zip = new JSZip()

      Promise.all(promiseList).then(() => {
        zip.generateAsync({
          type: 'blob'
        }).then(content => { // 生成二进制流
          FileSaver.saveAs(content, `商品图片-${this.productObj.productSn}.zip`) // 利用file-saver保存文件
        })
      })

    },
    onSwiper() {

    },
    onSlideChange() {

    },

    callback() {
    },
    callbackTab(key) {
      let doc = document.querySelector('.triangleTabs .ant-tabs-ink-bar')
      setTimeout(() => {
        doc.style.left = parseInt(doc.style.width) / 2 - 3 + 'px'
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.example {
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 270px 50px;
  margin: 20px 0;
  height: 550px;
}
.scale {
  margin: 15px 45px;
  display: flex;

  p {
    width: 25%;
    text-align: left;
    color: #7e7a7a;
  }
}

.commdisleft {
  width: 228px;
  border: 1px solid #ddd;
  margin-right: 20px;
  background: #fff;
  margin-bottom: 20px;
}

.commdis {
  display: flex;
  align-items: flex-start;
}

.list {
  text-align: center;
  border-bottom: 1px dashed #dbdbdb;
  padding: 30px 20px;
}

.title {
  padding: 0 14px;
  background-color: #fcfcfc;
  border-bottom: 1px solid #ddd;
  color: #666;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
}

#richText {
  width: 100%;

  ::v-deep img {
    height: auto;
    width: 100%;
  }
}

.store-navigation {
  width: unset;
}

.goods-describe {}

//面包屑
.breadcrumb {
  padding: 20px 22px;
  font-family: "PingFang SC", serif;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  color: #000;
}

h2 {
  font-family: "PingFang SC Bold", serif;
  font-weight: bold;
  font-size: 22px;
  text-indent: 14px;
  color: #202124;
  margin: 32px 0;
}

//商品信息

.title-top {
  font-family: "PingFang SC Bold", serif;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #202124;
  margin-bottom: 24px;
  //padding-left: 30px;

  .title-icon {
    color: #707070;
    margin-left: 12px
  }
}

.goods-info {
  background: #fff;
  //height: 300px;
  //width: 1500px;
  padding: 0 108px 58px 132px;
  display: flex;
}

//商品描述

@media (max-width: $large) {
  .goods-info {
    padding: 0 108px 58px 108px;
  }

  .aCol {
    display: none;
  }
}

@media (max-width: $small) {
  .goods-info {
    padding: 0 22px 58px 22px;
  }
}

.flex-row-center ::v-deep p {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ijs {
  position: fixed;
  z-index: 1000;
  top: 50%;
}
</style>
