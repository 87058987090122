<template>
  <div class="goods-left" style="margin-right: 20px;">
    <div class="goods-img">
      <div class="spec-preview">
        <div>
          <!--            视频暂时不显示-->
          <!--          <video-->
          <!--            v-if="isVideo"-->
          <!--            :src="productObj.productVideo"-->
          <!--            autoplay-->
          <!--            controls-->
          <!--            style="width: 388px;height: 388px"-->
          <!--          ></video>-->
          <img :src="mainImg" alt="" style="width: 410px;height: 408px" />
          <div class="event" @mousemove="getMousePositionOnImage"></div>
          <!-- 大图 -->
          <div v-if="!isVideo" class="big">
            <img ref="big" :src="mainImg" />
          </div>
          <!-- 遮罩 -->
          <div v-if="!isVideo" ref="mask" class="mask"></div>
        </div>
      </div>
    </div>
    <!-- flex-column -->
    <div class="goodsImgs">
      <div class="spec-box flex-between">
        <i class="icon el-icon-arrow-left" @click="leftBtn()"></i>
        <div class="spec-items">
          <div :style="{transform: `translateX(${carouselKm}px)`}" class="wrap">
            <div
              v-for="(item,index) in JSON.parse(productObj.image)"
              :key="index"
              :class="currentIndex===index?'actives':''"
              class="item"
              @mouseenter="changeImage(index)"
              @mouseleave="changeImage(index)">
              <img :ref="'img'+index" :src="item" alt="" @click="handleImageListItem(index, item)">
            </div>
            <!--            视频暂时不显示-->
            <!--            <div v-if="productObj.productVideo" class="item">-->
            <!--              <video-->
            <!--                :src="productObj.productVideo"-->
            <!--                style="width: 54px; height: 54px;"-->
            <!--                @click="isVideo=true"-->
            <!--              ></video>-->
            <!--            </div>-->
          </div>
        </div>
        <i class="icon el-icon-arrow-right" @click="rightBtn()"></i>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2023/1/3 11:34
 * @version 1.0
 * @description 左侧商品多媒体展示
 */
export default {
  name: 'GoodsMedia',
  props: {
    // 商品详情对象
    productObj: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      // 商品主图
      mainImg: this.productObj.imgUrl,
      // 判断选择的是否是视频
      isVideo: false,
      // 当前选中的选项
      currentIndex:0,
      // 轮播距离
      carouselKm: 0,  // 移动的距离
      allDistance: 0, // 总移动距离
    }
  },
  methods: {
    // 左边轮播
    leftBtn() {
      let imgUrlList = JSON.parse(this.productObj.image)
      if (this.currentIndex > 0) {
        this.currentIndex--    // 索引值-1
        imgUrlList.forEach((item, index) => {  // 循环小图,通过判断索引值赋值给大图
          if (this.currentIndex === index) {
            this.mainImg = item
          }
        })
      }
      if (this.currentIndex >= 4) {
        let index = 0
        const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
          if (index < 35) { // 移动次数(35次)
            this.carouselKm += 2 // 每次向左移动的距离 (移动总距离为35*this.carouselKm)
            index++
            return
          } else {
            window.clearInterval(temp) // 移动完清除定时器
          }
        }, 1)
      }
    },
    // 右边轮播
    rightBtn() {
      let imgUrlList = JSON.parse(this.productObj.image)
      if (this.currentIndex < imgUrlList.length - 1) {
        this.currentIndex++
        imgUrlList.forEach((item, index) => {
          if (this.currentIndex === index) {
            this.mainImg = item
          }
        })
        if (this.currentIndex >= 5) {
          this.allDistance = -70 * (this.currentIndex - 4)
          let index = 0
          const temp = window.setInterval(() => {
            if (index < 35) {
              this.carouselKm -= 2 // 每次向右移动的距离
              index++
              return
            } else {
              window.clearInterval(temp)
            }
          }, 1)
        }
      }
    },
    // 鼠标移入切换主图
    changeImage(key){
      this.mainImg = JSON.parse(this.productObj.image)[key]
    },
    //鼠标移入获取遮罩
    getMousePositionOnImage(event) {
      if (this.$refs.mask && this.$refs.big) {
        let mask = this.$refs.mask
        let big = this.$refs.big
        let left = event.offsetX - mask.offsetWidth / 2
        let top = event.offsetY - mask.offsetHeight / 2
        if (left <= 0) left = 0
        if (left >= mask.offsetWidth) left = mask.offsetWeight
        if (top <= 0) top = 0
        if (top >= mask.offsetHeight) top = mask.offsetHeight
        mask.style.left = left + 'px'
        mask.style.top = top + 'px'
        big.style.left = -2 * left + 'px'
        big.style.top = -2 * top + 'px'
      }
    },
    // 点击图片列表里面的图片
    handleImageListItem(i, item){
      this.currentIndex = i
      this.mainImg = item
      this.isVideo = false
      console.log('下标', i)
    },
  }
}
</script>

<style lang="scss" scoped>
.goods-img {
  margin: 0 auto;
}
.goodsImgs {
  height: auto;
  display: flex;
  align-items: center;
  .spec-box {
    display: flex;
    align-items: center;
  }
  .spec-box .icon {
    font-size: 36px;
    color: #aaa;
    cursor: pointer;
  }
  .spec-box .icon::before {
    font-family: element-icons!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
  }
  // 滚动条样式
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
    background-color: #99a9bf;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #d3dce6;
  }
}
.spec-preview {
  position: relative;
  width: 412px;
  height: 410px;
  border: 1px solid #ccc;
  img {
    width: 100%;
    height: 100%;
  }
  .event {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
  }
  .mask {
    width: 50%;
    height: 50%;
    background-color: rgba(0, 255, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }
  .big {
    width: 520px;
    height: 520px;
    position: absolute;
    top: -1px;
    left: 100%;
    border: 1px solid #aaa;
    overflow: hidden;
    z-index: 998;
    display: none;
    background: white;
    img {
      width: 200%;
      max-width: 200%;
      height: 200%;
      position: absolute;
      left: 15px;
      top: 0;
    }
  }
  .event:hover ~ .mask,
  .event:hover ~ .big {
    display: block;
  }
}
.spec-box{
  padding: 20px 0;
}
.spec-items {
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  //width: 380px;
  // overflow: hidden;
  // background: #a0bda2;tradePrice

}
.wrap{
  transition: all .5s ease;
  // display: flex;
  //width: 100%;

  // align-items: center;
  //overflow: hidden;
  //overflow-x: auto;
  //white-space: nowrap;
  // background: #007108;
  scrollbar-width: none;   /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.wrap::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  margin-top: 5px;
}
.wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(rgb(253, 159, 18), 30%);
}
.wrap::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#fd2900, 30%);
}


.item{
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  margin-right: 10px;
  vertical-align: bottom;

  // display: inline-block;
  //width: 58px;
  //height: 58px;
  //border: 2px solid #fff;
  //margin-right: 6px;
  // white-space:nowrap;

  img{
    width: 100%;
    height: 100%;
  }
}
.item:hover {
  border-color: #f55;
}

</style>
