<template>
  <div class="GoodsSkuTable">
    <!--  Sku过滤  -->
    <div class="filterBox">
      <!-- sku规格部分 -->
      <div v-for="(item, index) in productSpec" :key="index + r" class="flex-row" style="margin-top:10px">
        <div class="sku-label">{{ item.name }}：</div>
        <div class="sku-value">
          <a-button
            :class="['skuItem', { 'active': form[item.name] === '' }]"
            size="small"
            @click="handleSkuItem('全部', item)">
            全部
          </a-button>
          <a-button
            v-for="(val, i) in item.value"
            :key="index + '-' + i"
            :class="['skuItem', { 'active': form[item.name] === val }]"
            size="small"
            @click="handleSkuItem(val, item)">
            {{ val }}
          </a-button>
        </div>
      </div>
    </div>
    <p>
      {{ selectSkuList.length === 0 ? '全部' : '共匹配到' }}
      <span style="color:rgb(230,136,47)">{{ tableData.length }}</span>
      {{ selectSkuList.length === 0 ? '个规格型号' : '条数据' }}
    </p>
    <!--  表格  -->
    <a-table :columns="columns" :dataSource="tableData" :pagination="tableData.length > 50"
      :rowKey="(record) => record.id" :rowSelection="rowSelection" :scroll="{ x: 1150, y: 500 }" size="middle">
      <a slot="action">action</a>
      <!--      <div slot="price" slot-scope="text, record" style="color:rgb(230,136,47)">-->
      <!--        {{ text == null || text == undefined ? '' : text }}-->
      <!--      </div>-->
    </a-table>

    <div class="goods-form">
      <div class="flex-row flex-row-between" style="justify-content:  space-evenly;">
        <!--  运费   -->
        <div class="flex-row flex-col-center">
          <div class="describe-label">配送至：</div>
          <div class="cascader_length">
            <a-cascader ref="cascaderRef" :allowClear="true"
              :fieldNames="{ label: 'name', value: 'regionId', children: 'children' }" :options="cityTreeOptions"
              placeholder="请选择省市区" size="small" style="width: 250px;" @change="onChange" />
          </div>
          <div v-if="Object.keys(userInfo).length !== 0 && (userInfo.infoProfect !== 0 && userInfo.infoProfect !== 2)"
            class="ml-10">运费：￥{{ freightPrices.toFixed(2) }}</div>
        </div>
        <!--  数量   -->
        <div class="flex-row flex-col-center" style="margin-top:0px">
          <div class="describe-label" style="width:60px;">数量：</div>
          <el-input-number v-model="quantity" :max="selectSku.stock" :min="1" label="描述文字" size="small"
            @change="handleChange"></el-input-number>
          <div class="ml-10">
            (库存：{{ selectSku.stock === undefined ? 0 : selectSku.stock }}<span>件)</span>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(userInfo).length !== 0 && infoProfect !== 0" class="yunfei" @click="onOpen">运费详情</div>
      <div class=" goods-button">
        <div v-if="Object.values(selectSku).length" style="color: rgba(0, 0, 0, 0.65);font-size: 14px;">商品金额：<span
            style="color: #ff4000;font-size: 20px;">{{ (selectSku.tradePrice * quantity).toFixed(2) }}</span>元</div>
        <a-button :disabled="shelf == '0'" :style="shelf !== '0' ? '' : 'background: #bfbfbf !important;'"
          class="purchase-button" style="margin-left: auto" type="primary" @click="onSubmit">
          {{ shelf !== '0' ? '立即购买' : '已下架' }}
        </a-button>
        <a-button v-if="shelf !== '0'" class="car-button" style="margin-left: 10px;"
          @click.stop="handleHeart(productObj.id)">
          {{ isFavorite === 1 ? '已收藏' : '加入收藏' }}
        </a-button>
        <a-button
          v-if="Object.keys(userInfo).length !== 0 & userInfo.infoProfect !== 0 & userInfo.infoProfect !== 2 & shelf !== '0'"
          class="ru-button" style="margin-left: 10px;" @click.stop="handleAddGoods(productObj.id)">
          {{ productObj.isPuint == 1 ? '已入库' : '一键入库' }}
        </a-button>
      </div>

    </div>
<!--    运费详细-->
    <a-modal
      v-model="visible"
      :bodyStyle="{ height: '60vh', overflow: 'auto', paddingBottom: '35px' }"
      :footer="null"
      :maskClosable="false"
      title="运费详情"
      width="50%"
      @ok="visible = false">
      <div>
        <h5 style="display: inline-block;">计费方式：</h5>
        <el-tag>{{ type == '1' ? '按件数' : type == '2' ? '按重量' : type == '3' ? '按体积' : '' }}</el-tag>
      </div>
      <!-- 配送区域及运费 -->
      <div>
        <el-row :gutter="24" type="flex">
          <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
            <h5 style="margin: 15px 0;">配送区域及运费</h5>
            <el-table
              ref="table"
              :data="templateList"
              border
              empty-text="暂无数据"
            >
              <el-table-column align="center" label="可配送区域" prop="regionName" />
              <el-table-column align="center" label="首件" prop="first" width="120">
                <template slot="header" slot-scope="scope">
                  <span v-if="form.type == '1'">首件</span>
                  <span v-else-if="form.type == '2'">首件重量(KG)</span>
                  <span v-else>首件体积(m³)</span>
                </template>
                <template slot-scope="scope">
                  <span>{{ scope.row.first }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="运费（元）" prop="price">
                <template slot-scope="scope">
                  <span>{{ scope.row.price }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="续件" prop="continues">
                <template slot="header" slot-scope="scope">
                  <span v-if="form.type == '1'">续件</span>
                  <span v-else-if="form.type == '2'">续件重量(KG)</span>
                  <span v-else>续件体积(m³)</span>
                </template>
                <template slot-scope="scope">
                  <span>{{ scope.row.continues }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="续费（元）" prop="continuePrice">
                <template slot-scope="scope">
                  <span>{{ scope.row.continuePrice }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!--        开启指定包邮-->
      <el-row v-if="appoint == '1'" :gutter="24" type="flex">
        <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
          <div style="margin: 15px 0;">
            <h5>指定包邮</h5>
          </div>
          <el-table
            ref="table"
            :data="appointList"
            border
            empty-text="暂无数据"
          >
            <el-table-column align="center" label="选择地区" prop="placeName" />
            <el-table-column align="center" label="包邮件数" prop="num">
              <template slot="header" slot-scope="scope">
                <span v-if="type == 1">包邮件数</span>
                <span v-else-if="type == 2">包邮重量(KG)</span>
                <span v-else>包邮体积(m³)</span>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.num }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="包邮金额（元）" prop="price">
              <template slot-scope="scope">
                <span>{{ scope.row.price }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </a-modal>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2023/1/3 15:46
 * @version 1.0
 * @description 商品Sku表格
 */

// 用于比较两个数据是否相等
import equals from 'lodash.eq'
// 返回一个包含所有传入数组交集元素的新数组
import intersection from 'lodash.intersection'
import { mapGetters } from 'vuex'
import { favoriteProduct, freightProduct, inquireCityTree, templateById } from '@/api/Product'
export default {
  name: 'GoodsSkuTable',
  props: {
    // 商品详情对象
    productObj: {
      type: Object,
      required: true
    },
    // 商品规格列表
    productSpec: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      type: null,
      // 表格列配置
      columns: [
        {
          title: '价格(元)',
          width: 120,
          dataIndex: 'tradePrice',
          customRender: (text) => (<span style="color:rgb(230,136,47)">{text == null || text == undefined ? '' : text}</span>),
          // scopedSlots: { customRender: 'price' },
          fixed: 'right',
          align: 'center'
        },
        { title: '库存', width: 100, dataIndex: 'stock', fixed: 'right' },
        // {
        //   title: 'Action',
        //   key: 'operation',
        //   fixed: 'right',
        //   align: 'center',
        //   width: 100,
        //   scopedSlots: { customRender: 'action' },
        // },
      ],
      // 表格数据
      tableData: [],
      // 选择表格功能的配置
      rowSelection: {
        //  多选/单选，checkbox | radio
        type: 'radio',
        // 把选择框列固定在左边
        fixed: true,
        // 选中项发生变化时的回调
        onSelect: this.onChangeSelect
      },
      // 是否收藏
      isFavorite: this.productObj.isFavorite,
      // 是否下架
      shelf: this.productObj.shelf,
      // 格式化后用于渲染Sku规格的数据 (增加了"全部"选项)
      spec: [],
      // 选择的Sku过滤表单选项
      form: {},
      // 选中格式化好的 Sku数组 [sku1,sku2 ....]
      selectSkuList: [],
      // 随机数 用于改变Vue实例的Key 以达到重新渲染的目的
      r: Math.random() * 1000,
      // 选中要购买的SKU
      selectSku: {},
      // 购买数量
      quantity: 1,
      // 省市区树状数据
      cityTreeOptions: [],
      // 运费
      freightPrices: 0,
      // 选择的地址编码
      newAddress: [],
      //
      NumberFlush: Math.random() * 1000,
      // 运费数据
      visible: false,
      appointList: [],
      templateList: [],
      appoint: 1
    }
  },
  created() {
    // 查询省市区树状结构
    if (Object.keys(this.userInfo).length > 0) {
      inquireCityTree().then((res) => {
        this.cityTreeOptions = res.data
      }).catch((err) => {
        console.error('请求错误', err)
      })
    }
    // 处理接口返回的Sku数据
    let goodsSkuArry = []
    if (this.productObj.skus && this.productObj.skus.length) {
      this.productObj.skus.forEach(item => {
        if (item.specs.length) goodsSkuArry = item.specs
      })
    }
    // 根据规格列表添加 表格列配置项
    this.productSpec
      .map((item, index) => {
        // 在规格里面添加index, 因为要比较多item的sku需要固定在左侧, index是用来取tableData数据用
        item.index = index
        // 初始化 "选择的Sku过滤表单" 数据, 默认为"全部" 选项
        this.form[item.name] = ''
        return item
      })
      .sort((a, b) => a.value.length - b.value.length)
      .forEach((item, index) => {
        let indexs = goodsSkuArry.findIndex(val => val.productSpecName == item.name)  // 接收数据做对比，返回对应的下标
        this.columns.unshift({
          title: item.name,
          align: 'center',
          width: item.index === 0 ? 150 : undefined,
          dataIndex: `specs[${indexs}].productSpecValueName`,
          fixed: item.index === 0 ? 'left' : undefined
        })
      })

    // 去除没有开启的SKU数据
    if (this.productObj.skus && this.productObj.skus.length) {
      this.tableData = this.productObj.skus.filter((item) => {
        return Number(item.enable) === 1
      })
    }
    if (this.tableData.length) {
      this.rowSelection = {
        type: 'radio',
        fixed: true,
        onSelect: this.onChangeSelect,
        getCheckboxProps: (record) => ({
          props: {
            defaultChecked: record.id === this.tableData[0].id
          }
        })
      }
    }
    this.selectSku = this.tableData[0]
    console.log('this.selectSku', this.tableData[0])
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
  },
  methods: {
    // 运费
    onOpen() {
      templateById(this.productObj.id).then(res => {
        this.productObj.templates = res.data
        this.appoint = res.data.appoint ? 1 : 0
        this.type = res.data.type
        this.$nextTick(() => {
          this.appointList = JSON.parse(res.data.appointInfo)
          this.templateList = JSON.parse(res.data.regionInfo)
        })
      }).catch(err => {
        console.error(err)
      })
      this.visible = true
    },
    // 选择表格中的规格
    onChangeSelect(record, selected, selectedRows, nativeEvent) {
      console.log('按钮', record)
      this.selectSku = record
    },
    onSubmit() {
      if (Object.keys(this.userInfo).length === 0 && this.infoProfect !== 1) {
        this.$message.warning(Object.keys(this.userInfo).length === 0 ? '请先登录' : this.infoProfect === 0 ? '账号信息未完善' : this.infoProfect === 2 ? '待后台审核信息' : '')
        if (this.$tp().IS_OPEN == 1) {
          let tps = window.origin + '/user/login'
          window.open(tps, '_blank')
        } else {
          this.$router.push({ path: '/login' })
        }
        return
      }
      if (Object.keys(this.userInfo).length && this.infoProfect !== 1) {
        this.$message.warning(Object.keys(this.userInfo).length === 0 ? '请先登录' : this.infoProfect === 0 ? '账号信息未完善' : this.infoProfect === 2 ? '待后台审核信息' : '')
        if (this.$tp().IS_OPEN == 1) {
          let tps = window.origin + '/user/login'
          window.open(tps, '_blank')
        } else {
          this.$router.push({ path: '/login' })
        }
        return
      }
      if (Object.keys(this.selectSku).length === 0) {
        this.$notification.error({
          message: `规格未选择`,
          description:
            `您还未选择规格,请您选择后重新再试.`,
        })
        return
      }
      if (this.selectSku.stock <= 0 || this.quantity <= 0) {
        this.$message.warning('该商品库存或者数量不足')
        return
      }
      if (this.quantity > this.selectSku.stock) {
        this.$message.warning('该商品库存或者数量不足')
        return
      }

      this.$router.push({
        name: 'CheckOrder',
        params: {
          'skuInfo': this.selectSku,
          quantity: String(this.quantity),
          productId: this.productObj.id,
          spuInfo: JSON.stringify(this.productObj),
          'freightPrices': 0
        }
      })
    },
    // 选择地址的回调
    onChange(val) {
      if (this.infoProfect === 0 || this.infoProfect === 2) {
        this.$message.warning(this.infoProfect === 0 ? '去完善信息' : '待后台审核')
        return
      }
      this.newAddress = val
      const obj = {
        cityId: val[1],
        productId: this.productObj.id ? this.productObj.id : '',
        productNum: this.quantity,
        skuId: this.selectSku.id
      }
      if (Object.keys(this.selectSku).length) {
        freightProduct(obj).then(res => {
          this.freightPrices = res.data ? res.data : 0
        }).catch(err => {
          console.error(err)
        })
      }
      console.log('地址', obj)
    },
    // 数量变更的回调
    handleChange(value) {
      this.quantity = value
      if (Object.keys(this.selectSku).length && this.newAddress.length) {
        const obj = {
          cityId: this.newAddress[1],
          productId: this.productObj.id ? this.productObj.id : '',
          productNum: this.quantity,
          skuId: this.selectSku.id
        }
        freightProduct(obj).then(res => {
          this.freightPrices = res.data ? res.data : 0
        }).catch(err => {
          console.log('请求错误数据', err)
        })
      }
    },
    // 一键入库
    handleAddGoods(item) {
      if (this.productObj.isPuint === 1) {
        this.$message.warning('该商品已经入库')
        return
      }
      const that = this
      const ids = [item ? item : null]
      this.$AddGroupModal({
        ids,
        handleOk: (val) => {
          if (val === 'ok') {
            this.productObj.isPuint = 1
            this.$emit('ok', true)
          }
        }
      })
    },
    handleHeart(id) {
      if (!Object.keys(this.userInfo).length) {
        this.$message.warning('请先登录')
        return
      }
      if (this.infoProfect === 0 || this.infoProfect === 2) {
        this.$message.warning(this.infoProfect === 0 ? '该账号信息未完善' : this.infoProfect === 2 ? '等待后台审核' : '')
        return
      }

      favoriteProduct(id).then((res) => {
        this.isFavorite === 1 ? this.isFavorite = 0 : this.isFavorite = 1
      })
    },
    /**
     * 点击SKU选项
     * @param val {string} skuValue
     * @param item {{name: string,value: Array<string>}} 整个规格类
     */
    handleSkuItem(val, item) {
      if (val=='全部'){
        this.selectSku = this.tableData[0] || this.productObj.skus[0]
      }else {
        this.tableData.forEach(item => {
          if (item.specNames.length) {
            if (item.specNames[0] == val) {
              this.selectSku = item
            }
          }
        })
      }
      // 判断传入的值是否为全部 (没有反选功能)
      if (val === '全部') {
        this.form[item.name] = ''
      } else {
        this.form[item.name] = val
      }
      // 获取选中的数组
      let selectVal = Object.values(this.form).filter((item) => {
        return item !== ''
      })
      // 去除重复数据 因为Sku导入的时候 后端可能出现一些重复数据
      selectVal = new Set(selectVal)
      selectVal = [...selectVal]

      // 比较原来的选择的数据 和 现在选择的数据是否一致
      if (equals(this.selectSkuList, selectVal)) {
        // 如果一致的话, 直接结束方法, 节省性能消耗
        return
      } else {
        this.selectSkuList = selectVal
      }

      // 筛选出 存在选中的"过滤sku" 值的数据
      let data = []

      data = this.productObj.skus.filter((item) => {
        // 去除没有开启的SKU数据
        if (Number(item.enable) !== 1) {
          return false
        }
        /**
         *  过滤出 存在选中的"过滤sku" 值的数据
         *  取 "sku表的规格值" 和 "选中的Sku值" 两个数组的交集
         *  如果 "交集的长度" = "选中的Sku值"
         *  该数据有效
         */
        if (intersection(item.specNames, this.selectSkuList).length === this.selectSkuList.length) {
          return true
        } else {
          return false
        }
      })

      this.tableData = data

      // 刷新随机数
      this.r = Math.random() * 1000

    }
  }
}
</script>

<style lang="scss" scoped>
.yunfei {
  text-indent: 1.1rem;
  padding-top: 10px;
  color: #409eff;
}
.yunfei:hover {
  color: #0a80f8;
  cursor: pointer;
}
.yunfei:active{
  color: #075ebb;
}

::v-deep .ant-table-body,
::v-deep .ant-modal-body,
.ant-table-header,
::v-deep .ant-table-body-inner {
  word-break: break-all;

  &::-webkit-scrollbar {
    width: 8px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(144, 147, 153, 0.5);
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(144, 147, 153, 0.5);
    // border-style: dashed;
    // border-color: transparent;
    // border-top-width: 2px;
    // background-clip: padding-box;
    // border-left-width: 0;
    // border-right-width: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(157, 165, 183, 0.7);
    transition: 0.5s;
  }
}


.cascader_length .ant-cascader-picker {
  width: 220px !important;
}

.goods-form {
  position: sticky;
  padding: 10px;
  border: 1px solid #ccc;
  bottom: 0;
  z-index: 990;
  background-color: #f9f9f9;
}

.goods-button {
  margin-top: auto;
  height: 66px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: "PingFang SC Bold", serif;
  font-weight: bold;
  font-size: 14px;

  .ant-btn {
    width: 126px;
    min-width: 100px;
    height: 40px;
    border-radius: 2px;
    color: #202124;
    border: none;
  }

  .purchase-button {
    background: rgb(253, 159, 18) !important;
    border-radius: 4px !important;
    color: #fff;
  }

  .car-button {
    background: #fff !important;
    border: 1px solid rgb(253, 159, 18) !important;
    color: rgb(253, 159, 18);
    border-radius: 4px !important;

  }

  .ru-button {
    background: rgb(12, 187, 140) !important;
    color: #fff;
    border-radius: 4px !important;
  }

  .icon {
    font-size: 20px;
    transform: translate(10px, 4px);
  }
}

.filterBox {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 15px auto;
  width: 100%;
  padding: 20px 20px 0;
  text-align: left;

  .sku-label {
    width: 86px;
    height: 24px;
    margin-top: 5px;
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sku-value {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    >.skuItem {
      min-width: 60px;
      height: 28px;
      padding: 6px 12px;
      border-color: #e5e5e5;
      color: #222;
      border-radius: 0;
      font-weight: 400;
      margin: 4px 6px;

      &:hover {
        color: $color-orange;
        border-color: $color-orange;
      }

      &.active {
        color: $color-orange;
        border-color: $color-orange;
      }
    }
  }
}
</style>
