<template>
  <div class="goods-right">
    <!-- 国家-品牌   -->
    <div class="describe-top">
      <span>{{ productObj.countryName }}</span>
      <span>|</span>
      <span>{{ productObj.brandName === null ? '其他' : productObj.brandName }}</span>
    </div>
    <!--  贸易类型 商品名  -->
    <div class="flex-row flex-col-center describeMargin">
      <div class="tag">{{ productObj.tradeType | tradeTypeMapFilter }}</div>
      <div class="describe-title">{{ productObj.name }}</div>
    </div>

    <!--  其他部分  -->
    <div>
      <!--  价格   -->
      <div class="flex-row flex-col-center" style="background: rgb(242 236 236);;margin: 5px 0;padding: 5px;">
        <div class="describe-label" style="width:60px;">价格：</div>
        <div v-if="Object.keys(userInfo).length !== 0 && (userInfo.infoProfect !== 0 && userInfo.infoProfect !== 2)" class="sfaf">
          <div class="describe-price">
<!--            ￥{{ priceRange[0] === priceRange[1] ? `${priceRange[0]}` : `${priceRange[0]} - ${priceRange[1]}` }}-->
            ￥{{ productObj.tradePrice ? productObj.tradePrice.toFixed(2) : '0.00' }}
          </div>
          <div class="osaf">
            <div>市场价：<span style="text-decoration: line-through;color: #606266;">￥{{ productObj.marketPrice ? productObj.marketPrice.toFixed(2) : '0.00' }}</span></div>
            <div>利润：<span>{{ (productObj.marketPrice - productObj.tradePrice).toFixed(2) }}</span></div>
            <div>利润率：<span>{{ (((productObj.marketPrice - productObj.tradePrice) / productObj.tradePrice) * 100).toFixed(2) }}%</span></div>
          </div>
        </div>
        <div v-else class="describe-price">
          {{ userInfo.infoProfect === 0 ? '账号信息未完善' : userInfo.infoProfect === 2 ? '账号信息待审核' : '价格登录可见' }}
        </div>
      </div>

<!--      商品Sku-->
      <div v-if="Object.keys(userInfo).length !== 0 && (userInfo.infoProfect !== 0 && userInfo.infoProfect !== 2) && productObj.skus">
        <GoodsSkuTable :product-obj="productObj" :product-spec="productSpec"></GoodsSkuTable>
      </div>
      <div v-else-if="Object.keys(userInfo).length == 0 || userInfo.infoProfect == 0 || userInfo.infoProfect == 2">
        <span class="dengl" @click="onJumpLogin">去登录</span>
      </div>
      <!--  产地    -->
      <!--      <div class="flex-row flex-col-center" style=";margin: 5px 0;padding: 5px;">-->
      <!--        <div class="describe-label" style="width:60px;">产地：</div>-->
      <!--        <div>中国</div>-->
      <!--      </div>-->
      <!--  品牌    -->
      <!--      <div class="flex-row flex-col-center" style=";margin: 5px 0;padding: 5px;">-->
      <!--        <div class="describe-label" style="width:60px;">品牌：</div>-->
      <!--        <div>{{ productObj.brandName === null ? '其他' : productObj.brandName }}</div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2023/1/3 13:59
 * @version 1.0
 * @description 商品详情页 右侧商品信息展示
 */
import TradeTypeMap from '@/Map/TradeTypeMap'
import { mapGetters } from 'vuex'
import GoodsSkuTable from '@/views/GoodsDetail/GoodsSelect/GoodsSkuTable'

export default {
  name: 'GoodsData',
  components: { GoodsSkuTable },
  props: {
    // 商品规格Sku
    productSpecSku: {
      type: Array,
      required: true
    },
    // 商品详情对象
    productObj: {
      type: Object,
      required: true
    },
    // 商品规格列表
    productSpec: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      // 选择的Sku
      ultimatelyResult: {},
      // 价格区间 [最小值, 最大值]
      priceRange: []

    }
  },
  filters: {
    // 返回贸易类型
    tradeTypeMapFilter(type) {
      return TradeTypeMap[type].text
    }
  },
  computed: {
    // 用户信息 & 是否填写完整信息
    ...mapGetters(['userInfo', 'infoProfect']),
  },
  created() {
    // 计算价格区间
    this.getPriceRange()
  },
  updated() {
    if (!this.productSpecSku.length) {
      this.productObj['skus'] = this.productSpecSku
    }
  },
  methods: {
    // 登录
    onJumpLogin () {
      let PAGE_URL = ''
      if (process.env.NODE_ENV === 'pre') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      } else if (process.env.NODE_ENV === 'production') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'https://console.wanjingai.com/index'  // 退出回到商务中心index页面
      } else {
        0 == this.$tp().IS_OPEN ? this.$router.push('/login') : PAGE_URL = 'http://cm.aijdan.com/index'  // 退出回到商务中心index页面
      }
      if (this.$tp().IS_OPEN == 1) window.open(PAGE_URL, '_blank')
    },
    // 计算价格区间
    getPriceRange() {
      /** 目前对接后端暂时取消区间价格显示 */
      // let minPrice, maxPrice
      // if (this.productObj.skus.length > 0) {
      //   minPrice = Number(this.productObj.skus[0].tradePrice)
      //   maxPrice = Number(this.productObj.skus[0].tradePrice)
      //   this.productObj.skus.forEach((item) => {
      //     // 校验开启状态
      //     if (String(item.enable) === '1'){
      //       Number(item.tradePrice) > maxPrice ? maxPrice = item.tradePrice : ''
      //       Number(item.tradePrice) < minPrice ? minPrice = item.tradePrice : ''
      //     }
      //   })
      // }
      // this.priceRange = [minPrice,maxPrice]
    }
  }
}
</script>

<style lang="scss" scoped>
.dengl {
  cursor: pointer;
  font-size: 16px;
  padding: 12px 42px;
  color: #ffffff;
  background: #ff9900;
  border-radius: 5px;
  width: 157px;
  height: 47px;
  line-height: 47px;
  text-align: center;
}
.sfaf {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.osaf {
  display: flex;
  align-items: center;
  div {
    padding: 0 15px;
    span {
      color: #ff2900;
      font-size: 16px;
      font-weight: 0;
    }
  }
}
.goods-right {
  flex: 1;
  //width: 62%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .describe-bottom-line{
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }
  .describe-top{
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
    span {
      margin-right: 10px;
    }
  }
  .describeMargin{
    margin: 10px 0;
  }
  .describe-price{
    color: #ff2900;
    font-size: 26px;
    font-weight: 600;
  }
  .describe-title{
    font-size: 18px;
    font-weight: 600;
    margin-left: 15px;
  }
  .describe-label{
    margin: 10px 10px;
  }
  .tag{
    padding: 1px 5px;
    line-height: 20px;
    width: 85px;
    text-align: center;
    height: 20px;
    color: #fff;
    font-size: 10px;
    background: rgb(255, 153, 0);
    border-radius: 3px
  }
  .ant-form.ant-form-horizontal {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex: 1;
  }

  .goods-form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .ant-form-item {
      margin-bottom: 10px;
    }

    ::v-deep .ant-form-item-label > label {
      font-family: "Roboto-Regular ", serif;
      font-weight: 400;
      text-align: left;
      color: #707070;;
    }

    ::v-deep .ant-form-item-label {
      text-align: center;
      width: 74px;
    }

    p {
      margin: 0;
      padding: 0;
    }
    .goods-button {
      margin-top: auto;
      height: 66px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-family: "PingFang SC Bold", serif;
      font-weight: bold;
      font-size: 14px;

      .ant-btn {
        width: 180px;
        min-width: 100px;
        height: 42px;
        border-radius: 2px;
        color: #202124;
        border: none;
      }

      .purchase-button {
        background: rgb(253, 159, 18) !important;
        border-radius: 4px !important;
        color: #fff;
      }

      .car-button {
        background:#fff !important;
        border: 1px solid rgb(253, 159, 18) !important;
        color: rgb(253, 159, 18) ;
        border-radius: 4px !important;

      }
      .ru-button{
        background:rgb(12, 187, 140) !important;
        color: #fff;
        border-radius: 4px !important;
      }

      .icon {
        font-size: 20px;
        transform: translate(10px, 4px);
      }
    }

    ::v-deep .goods-button .ant-col-14 {
      width: 100%;
    }

    .goods-button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

  }
}
</style>
