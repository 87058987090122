<template>
  <div class="goods-detail-contain-top">
    <div class="mt-20 flex-row">
      <GoodsMedia :product-obj="productObj"></GoodsMedia>
      <a-spin :spinning="spinning" style="width: 62%;" tip="Loading...">
        <GoodsData :product-obj="productObj" :product-spec="productSpec" :product-spec-sku="productSpecSku" :spinning="spinning"></GoodsData>
      </a-spin>
    </div>
  </div>
</template>

<script>
import GoodsMedia from '@/views/GoodsDetail/GoodsSelect/GoodsMedia'
import GoodsData from '@/views/GoodsDetail/GoodsSelect/GoodsData'

/**
 * @author  XuHongli
 * @date  2023/1/3 11:21
 * @version 1.0
 * @description 商品详情页 商品选择面板
 */
export default {
  name: 'GoodSelect',
  components: { GoodsData, GoodsMedia },
  props: {
    spinning: {
      type: Boolean,
      defaults: true
    },
    // 商品规格Sku
    productSpecSku: {
      type: Array,
      required: true
    },
    // 商品详情对象
    productObj: {
      type: Object,
      required: true
    },
    // 商品规格列表
    productSpec: {
      type: Array,
      required: true
    },
  }
}
</script>

<style lang="scss" scoped>
.goods-detail-contain-top {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 15px auto;
  width: 1200px;
  padding: 20px 20px 0;
  text-align: left;
}
</style>
