/**
 * @author  XuHongli
 * @date  2023/1/3 14:45
 * @version 1.0
 * @description 商品贸易类型 Map
 */

const TradeTypeMap = {
  1: {
    text: '保税直供'
  },
  2: {
    text: '完税进口'
  },
  3: {
    text: '国内贸易'
  },
  4: {
    text: '香港直邮'
  },
  5: {
    text: '海外直邮'
  }
}

export default TradeTypeMap
